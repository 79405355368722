import analyticsService from "../../services"
import store from "../../../../store";

export default {
    data() {
        return {
            searchedResultShow: false,
            records:{},
            country_id: (this.$route.query.country ? this.$route.query.country : this.defaultFilterCountryId()),
            hasSuperAdmin: false,
            statistics: {},
            filter: {
                access_type: 'clicked',
                search: "",
                page:1,
                country_id: ""
            },
            meta:{current_page:1}
        }
    },
    watch: {
        'country_id': function () {
            this.filter.statistics = true;
            this.records= {},
            this.$router.replace({query: { country: this.country_id }});
            this.filter.page = 1;
            this.filter.country_id = this.country_id;
            this.getData();
        },
    },
    mounted: function mounted() {
        if ('user_type' in store.state.user && store.state.user.user_type == "super_admin") {
            this.hasSuperAdmin = true;
        }
        this.filter.statistics = true;
        this.filter.country_id = this.country_id;
        this.getData();
        this.getNextRecord();
    },
    methods: {
        tabchange(tab) {
            this.filter.access_type = tab;
            this.meta.current_page = this.filter.page = 1;
            this.getData();
        },
        getData() {
            this.showLoader(true);
            this.loading = true;
            analyticsService.getReferralAnalytics(this.filter).then(response => {
                this.hideLoader();
                this.records = (this.filter.current_page > 1) ? {...this.records,...response.data.data} : response.data.data;
                if (response.data.statistics) {
                    this.filter.statistics = false;
                    this.statistics = response.data.statistics
                }
                this.loading = false;
            });
        },
        getNextRecord() {
            window.onscroll = () => {
                let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
                if (bottomOfWindow && this.meta.current_page != this.meta.last_page && !this.loading) {
                    this.filter.page = this.meta.current_page+1;
                    this.getData();
                }
            }
        },
        filterUpdate() {
            this.meta.current_page = 1;
            this.getData();
            this.searchedResultShow = true;
        },
        filterClear() {
            this.meta.current_page = 1;
            this.search = "";
            this.getData('pending');
            this.searchedResultShow = false;
        },
    }
}