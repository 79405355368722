<app-header menu="no">
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">{{ $route.query.stage? $route.query.stage: "" }}</h1>
    <a href="#" @click="$router.go(-1)" class="header-icon header-icon-1">
        <i class="fa fa-arrow-left"></i>
    </a>
    <router-link :to="{'name': 'partner-edit', params: {'id' : record.id}}" href="#" class="header-icon header-icon-2" v-if="hasPermission('partner_update_personal_detail')">
        <i class="far fa-edit"></i>
    </router-link>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">
    <div class="card card-style">
        <div class="content pb-3">
            <div class="row mb-0">
                <div class="col-4 font-600" v-if="record.user_name">Reffered By:</div>
                <div class="col-8" v-if="record.user_name">
                    <router-link :to="{'name': 'partner-detail', params: {'id' : record.user_id}}">
                        {{record.user_name}}
                    </router-link>
                </div>
                <div class="col-4 font-600" v-if="record.customer_name">Customer</div>
                <div class="col-8" v-if="record.customer_name">
                    <router-link :to="{'name': 'customer-detail', params: {'id' : record.customer_id}}">
                        {{record.customer_name}}
                    </router-link>
                </div>
                <div class="col-4 font-600">Link Access Via</div>
                <div class="col-8">{{record.sharing_medium}}</div>
                <div class="col-4 font-600">Link Access Device</div>
                <div class="col-8">
                    {{ record.access_device_type != '' ? record.access_device_type : '' }} 
                    {{ record.access_device_os != '' ? '('+record.access_device_os+')' : '' }}
                </div>
                <div class="col-4 font-600">Access Device Browser</div>
                <div class="col-8">{{ record.access_browser_name ? record.access_browser_name : "-"}}</div>
                <div class="col-4 font-600">Access Device IP</div>
                <div class="col-8">{{ record.access_device_ip ? record.access_device_ip : "-"}}</div>
                <div class="col-4 font-600">Link Access At</div>
                <div class="col-8">{{ localDateTime(record.link_accessed_at)}}</div>
            </div>
            <div class="row mb-0" v-if="record.register_after">
                <div class="col-4 font-600">Registered after</div>
                <div class="col-8"><span class="font-700">{{ record.register_after ? record.register_after: '-' }}</span> minutes</div>
            </div>
            <div class="row mb-0" v-if="record.order_after">
                <div class="col-4 font-600">Ordered after</div>
                <div class="col-8"><span class="font-700">{{ record.order_after ? record.order_after : "-" }}</span> minutes</div>
            </div>
        </div>
    </div>
</div>

<app-footer />