import api from "@/common/services/api";

export default new class {
    baseUrl = "";

    getSelectData(source) {
        return api.instance.get("/get-select-data",{
            params : source
        });
    }

    getReferralAnalytics(data) {
        return api.instance.get("/referral-analytics", {params: data});
    }

    getReferralAnalyticsDetails(id) {
        return api.instance.get("/referral-analytics/"+id);
    }
};