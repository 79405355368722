<app-header>
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Referral Analytics</h1>
    <a href="#" data-menu="searchMenu" class="header-icon header-icon-2">
        <i class="fa fa-search"></i>
    </a>
    <div class="header-icon header-icon-3 select-filter" style="width: 50px !important;" v-if="hasSuperAdmin">
        <app-select class="border-no custom-dropdown" name="country_id" placeholder=""
            :options="$store.state.selectData.countries" value-prop="id" v-model="country_id">
            <template v-slot:default="slotProps">
                {{ slotProps.item.emoji }}
            </template>
            <template v-slot:display="slotProps">
                <span class="font-600 me-2">{{ slotProps.item.emoji }}</span>
                {{ slotProps.item.name }}
            </template>
        </app-select>
    </div>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">
    <div class="text-center" v-if="searchedResultShow">
        <p class="font-15 font-200 my-2 letterSpacing text-secondary text-center text-capitalize">
            Search results for
            <span class="font-500 text-dark">'{{ filter.search }}'</span>
            <button @click="filterClear" class="btn gradient-red btn-xs letterSpacing font-500 ms-3"
                style="padding: 3px 10px !important;">Clear</button>
        </p>
    </div>
    <div class="content">
        <div class="row text-center">
            <div class="col-lg-3 col-md-3 col-sm-6 col-6 statisticCountCardContainer">
                <div class="card close-menu statisticCountCard">
                    <div class="card-body mt-3 d-flex flex-column justify-content-around">
                        <div class="d-flex justify-content-around align-items-center mb-3">
                            <i class="fas fa-clipboard-check icon-dash"></i>
                            <h3 class="card-title font-25 mt-2 primary-light-text">
                                {{ statistics.avg_registered ? statistics.avg_registered+' minutes' : '-' }}
                            </h3>
                        </div>
                        <p class="card-text font-14 letterSpacing font-500 primary-alt-base-text">Average Time For Registration</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-6 statisticCountCardContainer">
                <div class="card close-menu statisticCountCard">
                    <div class="card-body mt-3 d-flex flex-column justify-content-around">
                        <div class="d-flex justify-content-around align-items-center mb-3">
                            <i class="fas fa-clock icon-dash"></i>
                            <h3 class="card-title font-25 mt-2 primary-light-text">
                                {{ statistics.avg_ordered ? statistics.avg_ordered+' minutes' : '-' }}
                            </h3>
                        </div>
                        <p class="card-text font-14 letterSpacing font-500 primary-alt-base-text">Average Time For First Order</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card card-style">
        <div class="content mb-0 mt-3" id="tab-group-1">
            <div class="tab-controls tabs-small tabs-rounded" data-highlight="bg-primary-light-color">
                <a href="#" class="line-height-s p-1 btn-ripple letterSpacing" data-active data-bs-toggle="collapse"
                    @click="tabchange('clicked')" data-bs-target="#tab-1ab">{{ statistics.clicked ? statistics.clicked :
                    0 }}<br>Clicked</a>
                <a href="#" class="line-height-s p-1 btn-ripple letterSpacing" data-bs-toggle="collapse"
                    @click="tabchange('registered')" data-bs-target="#tab-2ab">{{ statistics.registered ?
                    statistics.registered : 0 }}<br>Registered</a>
                <a href="#" class="line-height-s p-1 btn-ripple letterSpacing" data-bs-toggle="collapse" @click="tabchange('ordered')"
                    data-bs-target="#tab-3ab">{{ statistics.ordered ? statistics.ordered : 0 }}<br>Ordered</a>
            </div>

            <div class="clearfix mb-3"></div>
            <div data-bs-parent="#tab-group-1" class="collapse show" id="tab-1ab">
                <div v-for="record in records" class="pt-2 mb-2 border-top">
                    <router-link
                        :to="{'name': 'referral_analytics_detail', params: {'id' : record.id}, query: {'stage': 'Clicked'}}">
                        <div class="d-flex">
                            <div class="align-self-center">
                                <p class="color-highlight font-15 mb-n1">Reff by: {{record.user_name ? record.user_name
                                    : "" }}</p>
                                <h2 class="font-12 line-height-s mt-1 mb-1">
                                    {{ record.sharing_medium ? record.sharing_medium : "" }}
                                </h2>
                            </div>
                            <div class="ms-auto ps-3 align-items-end">
                                <p class="font-10 mb-n2 text-end">{{ localDateTime(record.link_accessed_at) }}</p>
                                <p class="font-12 mb-0 text-end">
                                    {{ record.access_device_type != '' ? record.access_device_type : '' }}
                                    {{ record.access_device_os != '' ? '('+record.access_device_os+')' : '' }}
                                </p>
                            </div>
                            <div class="d-flex ps-2 align-items-center">
                                <i class="fa fa-chevron-right"></i>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="mt-5 text-center" v-if="!(records.length>0)">
                    <i class="fas fa-search font-20 text-secondary mb-3"></i>
                    <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
                        No Record Found
                    </p>
                </div>
            </div>
            <div data-bs-parent="#tab-group-1" class="collapse" id="tab-2ab">
                <div v-for="record in records " class="pt-2 mb-2 border-top">
                    <router-link
                        :to="{'name': 'referral_analytics_detail', params: {'id' : record.id}, query: {'stage': 'Registered'}}">
                        <div class="d-flex">
                            <div class="align-self-center">
                                <h2 class="font-15 color-highlight line-height-s mt-1 mb-1">
                                    {{ record.sharing_medium ? record.customer_name : "" }}
                                </h2>
                                <p class="font-12 mb-n1">Reff by: {{record.user_name ? record.user_name : "" }}</p>
                            </div>
                            <div class="ms-auto ps-3 align-items-end">
                                <p class="font-10 mb-n2 text-end">{{ localDateTime(record.link_accessed_at) }}</p>
                                <p class="font-12 mb-0 text-end" v-if="record.register_after !=''">
                                    Registered after: <span class="font-700">{{ record.register_after }}</span> minutes
                                </p>
                            </div>
                            <div class="d-flex ps-2 align-items-center">
                                <i class="fa fa-chevron-right"></i>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="mt-5 text-center" v-if="!(records.length>0)">
                    <i class="fas fa-search font-20 text-secondary mb-3"></i>
                    <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
                        No Record Found
                    </p>
                </div>
            </div>
            <div data-bs-parent="#tab-group-1" class="collapse" id="tab-3ab">
                <div v-for="record in records" class="pt-2 mb-2 border-top">
                    <router-link
                        :to="{'name': 'referral_analytics_detail', params: {'id' : record.id}, query: {'stage': 'Ordered'}}">
                        <div class="d-flex">
                            <div class="align-self-center">
                                <h2 class="font-15 color-highlight line-height-s mt-1 mb-1">
                                    {{ record.sharing_medium ? record.customer_name : "" }}
                                </h2>
                                <p class="font-12 mb-n1">Reff by: {{record.user_name ? record.user_name : "" }}</p>
                            </div>
                            <div class="ms-auto ps-3 align-items-end">
                                <p class="font-10 mb-n2 text-end pb-1">{{ localDateTime(record.link_accessed_at) }}</p>
                                <p class="font-12 mb-0 text-end" v-if="record.register_after !=''">
                                    Registered after: <span class="font-700">{{ record.register_after }}</span> minutes
                                </p>
                                <p class="font-12 mb-0 text-end" v-if="record.order_after !=''">
                                    Ordered after: <span class="font-700">{{ record.order_after }}</span> minutes
                                </p>
                            </div>
                            <div class="d-flex ps-2 align-items-center">
                                <i class="fa fa-chevron-right"></i>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="mt-5 text-center" v-if="!(records.length>0)">
                    <i class="fas fa-search font-20 text-secondary mb-3"></i>
                    <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
                        No Record Found
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="searchMenu" class="menu menu-box-bottom rounded-m" data-menu-height="300" data-menu-effect="menu-over">
    <div class="menu-title p-3 pb-2">
        <h2 class="letterSpacing font-17 font-200">Search By</h2>
        <a href="#" class="close-menu" ref="closeBtn"><i class="fa fa-times-circle"></i></a>
    </div>
    <div class="card-style shadow-0 rounded-0 mb-0">
        <div class="input-style has-borders">
            <input class="form-control font-15 font-400 letterSpacing" type="text" placeholder="Search " v-model="filter.search">
            <em></em>
            <i class="disabled invalid"></i>
            <i class="disabled valid"></i>
        </div>
    </div>
    <div class="content mt-0 d-flex justify-content-center">
        <button @click="filterUpdate" :disabled="!filter.search"
            class="close-menu btn btn-l btn-ripple me-1 primary-light-bg rounded-s letterSpacing">
            Search
        </button>
        <button @click="filterClear" :disabled="!filter.search"
            class="close-menu btn btn-l btn-ripple primary-alt-light-bg rounded-s letterSpacing">
            Clear
        </button>
    </div>
</div>

<app-footer />