import referralAnalyticsService from "../../services";

export default {
    data() {
        return {
            record: {},
        }
    },
    mounted() {
        this.showLoader(true);
        referralAnalyticsService.getReferralAnalyticsDetails(this.$route.params.id).then(response => {
            this.record = response.data.data;
            this.hideLoader();
        });
    },
    methods: {}
}